<template>
  <section id="apercu">
    <b-row>
      <b-col sm="12" md="12" lg="12" v-if="userData != []">
        <b-card v-if="userData.offre === 2 || userData.offre === 0">

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from '@axios'
import {
  BCard, BRow, BCol, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormFile, BCardText, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormFile,
    BCardText,
    BSpinner,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      userData: [],
      messages: [],
      ready: false,
      encours: null,
      edit: null,
      file: null,
      likedUsers: [
        /* eslint-disable global-require */
        { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
      ],
    }
  },
  methods: {

  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    axios
      .get('https://app2.oscar-black.com/api/moyenPaiement')
      .then(response => {
        window.location.replace(response.data.url, '_self');
      })
      .catch(() => {})
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
